/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql } from 'gatsby';
import React from 'react';

import {
  ContentfulEvent,
  ContentfulEventConnection,
  ContentfulLocation,
  ContentfulRichTextSection,
  ContentfulSettings,
  ContentfulUpcomingEvents,
  Site,
} from '../__generated__/graphql-types';
import Layout from '../components/layout';
import extractFileInforamtion from '../transforms/File';
import extractShortDescription from '../transforms/ShortDescription';
import transformUpcomingEventsSection from '../transforms/UpcomingEvents';
import { EventPage } from '../ui-lib/components';
import { handleNetlifyFormSubmission } from '../ui-lib/utils';
import richTextRawToJson from '../utils/contentful-rich-text-raw-to-json';

type EventQueryType = {
  contentfulEvent: ContentfulEvent;
  site: Site;
  allContentfulEvent: ContentfulEventConnection;
  contentfulSettings: ContentfulSettings;
  contentfulUpcomingEvents: ContentfulUpcomingEvents;
  translatedSlugs: ContentfulEventConnection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type EventProps = {
  data: EventQueryType;
  pageContext: PageContextType;
};

const getLocation = (location: ContentfulLocation | ContentfulRichTextSection | undefined) => {
  if (location?.__typename === 'ContentfulRichTextSection') {
    return location.richText?.raw ? documentToPlainTextString(JSON.parse(location.richText.raw)) : undefined;
  }

  if (location?.__typename === 'ContentfulLocation') {
    return `${location?.title} ${location.address?.address}`;
  }

  return undefined;
};

const Event = ({ data, pageContext }: EventProps) => {
  const {
    // title,
    // image,
    location: eventLocation,
    displayDate,
    shortDescription,
    content,
    showUpcomingEvents,
    document,
    updatedAt,
    hero,
    seoMetadata,
  } = data.contentfulEvent;

  const location = getLocation(eventLocation as ContentfulLocation | ContentfulRichTextSection | undefined);

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    id: pageContext.id,
    isHomepage: false,
    ogPageType: 'article',
    pageDescription: extractShortDescription(shortDescription as any),
    pageImage: hero?.image || undefined,
    pageLocale: pageContext.node_locale,
    pageSettings: seoMetadata,
    pageSlug: data.contentfulEvent.slug ? data.contentfulEvent.slug : '',
    pageTitle: hero?.title as string,
    pageType: 'event',
    siteUrl: data.site.siteMetadata?.siteUrl || '',
    swiftTypeDate: data.contentfulEvent.displayDate ? data.contentfulEvent?.displayDate : undefined,
    swiftTypeLocation: location,
    updatedAt,
  };

  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings.footerShowSubscribeForm || false,
    showFooterSubscribeFormCountryField: false,
    subscribeFormHeading: data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading || '',
  };

  return (
    <Layout pageProps={pageProps} subscribeForm={subscribeForm} translatedSlugs={data.translatedSlugs.nodes}>
      <EventPage
        pageTitle={pageProps.pageTitle}
        title={hero?.title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        eventDate={displayDate as string}
        eventLocation={location}
        contentsSection={{
          data: content ? richTextRawToJson(content.richText, pageContext.node_locale)?.json : null,
        }}
        image={hero?.image}
        handleFormSubmit={handleNetlifyFormSubmission as any}
        showContactsFormSubscribeOptions
        eventModuleProps={
          showUpcomingEvents
            ? transformUpcomingEventsSection(data.contentfulUpcomingEvents, data.allContentfulEvent)
            : null
        }
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default Event;

export const pageQuery = graphql`
  query ($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    translatedSlugs: allContentfulEvent(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulEvent(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      # TODO Legacy check if can be removed
      # title
      slug
      seoMetadata {
        id
        description
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      hero {
        title
        image {
          title
          alt: altText
          imageWithFocalPoint(quality: 80)
          socialImageWithFocalPoint: imageWithFocalPoint(
            quality: 80
            layout: FIXED
            width: 1200
            height: 630
            resizingBehavior: CROP
          )
          asset {
            gatsbyImageData(quality: 85)
          }
          socialImage: asset {
            gatsbyImageData(layout: FIXED, width: 1200, height: 630, resizingBehavior: CROP, quality: 100)
          }
        }
      }
      # TODO Legacy check if can be removed
      # image {
      #   title
      #   alt: altText
      #   asset {
      #     # fluid(quality: 85) {
      #     #   ...QueryImageFluid
      #     # }
      #     gatsbyImageData(quality: 85)
      #   }
      #   socialImage: asset {
      #     # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
      #     #   ...QueryImageFixed
      #     # }
      #     gatsbyImageData(layout: FIXED, width: 1200, height: 630, resizingBehavior: CROP, quality: 100)
      #   }
      # }
      location {
        __typename
        ... on ContentfulLocation {
          title
          address {
            address
          }
        }
        ... on ContentfulRichTextSection {
          richText {
            raw
          }
        }
      }
      # TODO - Legacy. Check in future to remove because startDate and endDate don't have the same format
      displayDate
      startDate
      endDate
      shortDescription {
        text: shortDescription
      }
      content {
        __typename
        alignment
        richText {
          raw
          references {
            __typename
            ... on ContentfulAdvice {
              contentful_id
              slug
            }

            ... on ContentfulBasicPage {
              contentful_id
              slug
            }

            ... on ContentfulEvent {
              contentful_id
              slug
            }

            ... on ContentfulNews {
              contentful_id
              slug
            }

            ... on ContentfulProduct {
              contentful_id
              slug
            }

            ... on ContentfulAsset {
              contentful_id
              file {
                url
              }
            }

            ... on ContentfulAttachment {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              asset {
                file {
                  url
                  details {
                    size
                  }
                  contentType
                }
              }
            }

            ... on ContentfulBlockquote {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              quote {
                text: quote
              }
              author
              authorInfo
              image {
                imageWithFocalPoint(quality: 80)
                asset {
                  file {
                    url
                  }
                  gatsbyImageData
                }
              }
            }

            ... on ContentfulImage {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              alt: altText
              imageWithFocalPoint(quality: 80)
              asset {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                gatsbyImageData
              }
            }

            ... on ContentfulMediaBlock {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              shortDescription {
                text: shortDescription
              }
              caption {
                text: caption
              }
              orientation
              media {
                ... on ContentfulImage {
                  __typename
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  alt: altText
                  portraitImageWithFocalPoint: imageWithFocalPoint(layout: CONSTRAINED, aspectRatio: 0.74, quality: 80)
                  squareImageWithFocalPoint: imageWithFocalPoint(layout: CONSTRAINED, aspectRatio: 1, quality: 80)
                  landscapeImageWithFocalPoint: imageWithFocalPoint(layout: CONSTRAINED, aspectRatio: 1.77, quality: 80)
                  asset {
                    file {
                      url
                    }
                    portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                    squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                    landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                  }
                }

                ... on ContentfulVideo {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  videoUrl
                }
              }
            }

            ... on ContentfulShortBanner {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              image {
                title
                alt: altText
                imageWithFocalPoint(quality: 80)
                asset {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                  gatsbyImageData
                }
              }
              cta {
                title
                internalEntry {
                  __typename
                  ... on ContentfulAdvice {
                    contentful_id
                    slug
                  }

                  ... on ContentfulBasicPage {
                    contentful_id
                    slug
                  }

                  ... on ContentfulEvent {
                    contentful_id
                    slug
                  }

                  ... on ContentfulNews {
                    contentful_id
                    slug
                  }

                  ... on ContentfulProduct {
                    contentful_id
                    slug
                  }
                }
                externalUrl
              }
            }

            ... on ContentfulTextSectionHighlight {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              richTextContents {
                raw
                references {
                  __typename
                  ... on ContentfulAdvice {
                    contentful_id
                    slug
                  }

                  ... on ContentfulBasicPage {
                    contentful_id
                    slug
                  }

                  ... on ContentfulEvent {
                    contentful_id
                    slug
                  }

                  ... on ContentfulNews {
                    contentful_id
                    slug
                  }

                  ... on ContentfulProduct {
                    contentful_id
                    slug
                  }

                  ... on ContentfulAsset {
                    contentful_id
                    file {
                      url
                    }
                  }
                }
              }
            }

            ... on ContentfulVideo {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              videoUrl
            }

            ... on ContentfulRichTextSection {
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              alignment
              richText {
                raw
                references {
                  __typename
                  ... on ContentfulAdvice {
                    contentful_id
                    slug
                  }

                  ... on ContentfulBasicPage {
                    contentful_id
                    slug
                  }

                  ... on ContentfulEvent {
                    contentful_id
                    slug
                  }

                  ... on ContentfulNews {
                    contentful_id
                    slug
                  }

                  ... on ContentfulProduct {
                    contentful_id
                    slug
                  }

                  ... on ContentfulAsset {
                    contentful_id
                    file {
                      url
                    }
                  }

                  ... on ContentfulAttachment {
                    # Attachment does not need focal point!!!
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    asset {
                      file {
                        url
                        details {
                          size
                        }
                        contentType
                      }
                      gatsbyImageData
                    }
                  }

                  ... on ContentfulBlockquote {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    quote {
                      text: quote
                    }
                    author
                    authorInfo
                    image {
                      title
                      alt: altText
                      imageWithFocalPoint(quality: 80)
                      asset {
                        file {
                          url
                        }
                        gatsbyImageData
                      }
                    }
                  }

                  ... on ContentfulImage {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    alt: altText
                    imageWithFocalPoint(quality: 80)
                    asset {
                      file {
                        url
                        details {
                          image {
                            width
                            height
                          }
                        }
                      }
                      gatsbyImageData
                    }
                  }

                  ... on ContentfulMediaBlock {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    shortDescription {
                      text: shortDescription
                    }
                    caption {
                      text: caption
                    }
                    orientation
                    media {
                      ... on ContentfulImage {
                        __typename
                        sys {
                          contentType {
                            sys {
                              id
                            }
                          }
                        }
                        title
                        alt: altText
                        portraitImageWithFocalPoint: imageWithFocalPoint(
                          layout: CONSTRAINED
                          aspectRatio: 0.74
                          quality: 80
                        )
                        squareImageWithFocalPoint: imageWithFocalPoint(layout: CONSTRAINED, aspectRatio: 1, quality: 80)
                        landscapeImageWithFocalPoint: imageWithFocalPoint(
                          layout: CONSTRAINED
                          aspectRatio: 1.77
                          quality: 80
                        )
                        asset {
                          file {
                            url
                          }
                          portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                          squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                          landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                        }
                      }

                      ... on ContentfulVideo {
                        contentful_id
                        sys {
                          contentType {
                            sys {
                              id
                            }
                          }
                        }
                        videoUrl
                      }
                    }
                  }

                  ... on ContentfulShortBanner {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    image {
                      title
                      alt: altText
                      imageWithFocalPoint(quality: 80)
                      asset {
                        file {
                          url
                          details {
                            image {
                              width
                              height
                            }
                          }
                        }
                        gatsbyImageData
                      }
                    }
                    cta {
                      title
                      internalEntry {
                        __typename
                        ... on ContentfulAdvice {
                          contentful_id
                          slug
                        }

                        ... on ContentfulBasicPage {
                          contentful_id
                          slug
                        }

                        ... on ContentfulEvent {
                          contentful_id
                          slug
                        }

                        ... on ContentfulNews {
                          contentful_id
                          slug
                        }

                        ... on ContentfulProduct {
                          contentful_id
                          slug
                        }
                      }
                      externalUrl
                    }
                  }

                  ... on ContentfulTable {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    table {
                      tableData
                    }
                    fixFirstColumn
                  }

                  ... on ContentfulTextSectionHighlight {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    richTextContents {
                      raw
                      references {
                        __typename
                        ... on ContentfulAdvice {
                          contentful_id
                          slug
                        }

                        ... on ContentfulBasicPage {
                          contentful_id
                          slug
                        }

                        ... on ContentfulEvent {
                          contentful_id
                          slug
                        }

                        ... on ContentfulNews {
                          contentful_id
                          slug
                        }

                        ... on ContentfulProduct {
                          contentful_id
                          slug
                        }

                        ... on ContentfulAsset {
                          contentful_id
                          file {
                            url
                          }
                        }
                      }
                    }
                  }

                  ... on ContentfulVideo {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    videoUrl
                  }
                }
              }
            }
          }
        }
      }
      showUpcomingEvents
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    contentfulUpcomingEvents(metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }) {
      ...QueryUpcomingEvents
    }

    allContentfulEvent(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          updatedAt(formatString: "YYYYMMDDHHmmss")
          id
          # TODO check if can be removed
          # displayDate
          startDate
          slug
          shortDescription {
            text: shortDescription
          }
          location {
            __typename
            ... on ContentfulLocation {
              title
              address {
                address
              }
            }
            ... on ContentfulRichTextSection {
              richText {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
